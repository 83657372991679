<template>
    <div>
        <div class="col-12">
            <div :class="CorCard">
                <div class="card-header">
                    <h4 class="card-title">
                    </h4>
                </div>
                <div class="card-body">
                        <div>
                            <b-row style="gap: .5rem;">

                                <div id="loadingMessage" hidden="">Carregando video...</div>
                                <canvas id="canvas"></canvas>
                                <div id="output">
                                    <div id="outputMessage"></div>
                                    <div hidden=""><span id="outputData"></span></div>
                                </div>

                            </b-row>
                        </div>
                </div>
            </div>
        </div>

        <div class="col-12 containerCard">
            <div class="card" ref="backgroundValidaProd"><!-- style="background: #ffcc01;" -->
                <div class="card-body pb-50" style="position: relative;">
                    <h4 class="fw-bolder mb-1">Total Retiradas <span id="infoSpan">(Mês)</span>
                        <h5 style="font-size: 1.5rem; color: #55DD92; float:right;">{{ TOTALCESTAMES }}</h5>
                    </h4>
                </div>
            </div>
        </div>

        <div class="col-12" id="containerTabela">
            <!-- <div class="card mb-4"> -->
            <div class="card mb-4" >
              <div class="card-body pb-60" >
                <div >
                    <b-table
                        :items="ListaFunc"
                        :fields="ListaFuncfields"
                        striped
                        hover
                        responsive
                        small
                        sticky-header
                        table-class = "font-size10"
                        thead-class = "font-size10"
                        thead-tr-class = "font-size10"
                    >
                        <template #cell(show_details)="row">
                            <b-button id="btnBaixa" ref="btnBaixa" size="sm" variant="success" @click="DarBaixa([row.item.CODFUNC],
                                                                                                                [row.item.CODEMP],
                                                                                                                [row.item.AD_PRODVAPZA],
                                                                                                                [row.item.AD_CHAVEPONTO])" class="mr-2 btnBaixa">
                                OK
                            </b-button>
                        </template>

                        <template #cell(Codfunc)="row">
                            {{ row.value }}
                        </template>

                        <template #cell(PRODUTOSVAPZA)="row">
                            <b ref="LinhaProdVapza">{{ row.value}}</b>
                        </template>

                        <template #cell(Retirada)="row">
                            {{ row.value}}
                        </template>
                    </b-table>
                </div>
             </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import VueApexCharts from 'vue-apexcharts'
    import 'bootstrap-icons/font/bootstrap-icons.css'
    import apiPortalSky from '@/services/apiPortalSky'
    import AlertaMsg from '@/customPortal/AlertaMsg.vue'
    import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
    import Spinner from '@/components/Spinner.vue'
    import jsQR from "jsqr";
    import {
    BFormGroup,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadio,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    } from 'bootstrap-vue'

    export default {
        components:{
            VueApexCharts,
            Multiselect,
            BFormGroup,
            BFormDatepicker,
            BFormRadio,
            BRow,
            BCol,
            BButton,
            BTable,
            BFormSelect,
            BFormInput,
            BCollapse,
            BCard,
        },
        data(){
            return{
                showFiltro: true,
                ATIVABTN: false,
                TOTALCESTAMES: '0',
                dataQR: '',
                timeQr: 0,
                CorCard:'card mb-4 Inicio',
                FiltroTela: {
                    QRCODCOLAB: "",
                    BuscarPor:'QRCODCOLAB'
                },
                BuscarPorOptions: [
                    { value: 'QRCODCOLAB', text: 'Código' },
                ],

                ListaFunc: [
                {CODFUNC: ""},
                {NOMEFUNC: ""},
                {RETIRADA: ""},
                {PRODUTOSVAPZA: ""},
                {CODEMP: ""},
                {AD_PRODVAPZA: ""},
                {msg: ""},
                {AD_CHAVEPONTO: ""},
                ],
                ListaFuncfields: [
                    { key: 'show_details', label: 'Validar' ,sortable: false  },
                    { key: 'CODFUNC', label: 'N° Funcionário' ,sortable: true, tdClass: 'td100' }.hide,
                    { key: 'NOMEFUNC', label: 'Funcionário' ,sortable: true, tdClass: 'td100' },
                    { key: 'DESCRCENCUS', label: 'Cent Resultado' ,sortable: true, tdClass: 'td100' }.hide,
                    { key: 'PRODUTOSVAPZA', label: 'Produtos' ,sortable: true, tdClass: 'td100' },
                    { key: 'RETIRADA', label: 'Última Retirada' ,sortable: true, tdClass: 'td100' }.hide,
                    { key: 'CODEMP', label: 'CODEMP' ,sortable: true, tdClass: 'td100' }.hide,
                    { key: 'AD_PRODVAPZA', label: 'AD_PRODVAPZA' ,sortable: true, tdClass: 'td100' }.hide,
                    { key: 'AD_CHAVEPONTO', label: 'AD_CHAVEPONTO' ,sortable: true, tdClass: 'td100' }.hide,
                ],
            }
        },
        methods:{
            async MostraReport() {
                let notification = ""
                Spinner.StarLoad()
                const usuario = this.$session.get('usuario')

                let ParametroJson = ""
                let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
                await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                    if (response.status === 200) {
                        if (response.data != null) {
                            this.RelName = response.data.descricaoRelatorio
                        } else {
                            notification = ConexaoAPI.MsgFiltroSemRetorno()
                            AlertaMsg.FormataMsg(notification)
                        }
                    }
                }).catch(error => {
                    notification = ConexaoAPI.ValidaErro(error)
                    AlertaMsg.FormataMsg(notification)
                })
                if(notification === '') {
                    Spinner.EndLoad()
                }
            },
            async Buscar(qrCode){
                Spinner.StarLoad()
                this.showFiltro = false

                const usuario = this.$session.get('usuario')
                let notification = ""

                let ParametroJson = ''
                let URLpost = ''

                this.ListaFunc = []

                if(this.NRCOLAB != "" || this.QRCODCOLAB != ""){
                    this.ATIVABTN = true
                    
                }
                
                    URLpost = `/Cesta/BuscarCesta?CODUSU=${usuario.codusu}&_qrCode=${qrCode}`
                    await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                        if (response.status === 200) {
                            if(response.data.length > 0){
                                this.ListaFunc = response.data
                                this.FiltroTela.QRCODCOLAB = null
                                if (this.ListaFunc[0].msg == null)
                                {
                                    this.ListaFunc[0].msg= ''
                                }
                                this.TOTALCESTAMES = response.data[0].TOTALCESTAMES
                                        if (this.TOTALCESTAMES == null) {
                                            this.TOTALCESTAMES = '0'
                                        }
                            }
                        }
                        
                    }).catch(error => {
                        this.FiltroTela.QRCODCOLAB = null
                        notification = ConexaoAPI.ValidaErro(error)
                        AlertaMsg.FormataMsg(notification)
                    })
                    
                    if(this.ListaFunc[0].PRODUTOSVAPZA == "Liberado" || this.ListaFunc[0].PRODUTOSVAPZA == "Liberado (RH)"){
                        this.$refs.LinhaProdVapza.style.color = '#28c76f';
                    }else{
                        this.$refs.LinhaProdVapza.style.color = '#ffcc01';
                    }

                    if(this.ListaFunc[0].msg != ''){
                        this.$refs.btnBaixa.style.display = "none";
                    }
                    
                    if (this.ListaFunc[0].msg != '') {
                                    notification = { message: this.ListaFunc[0].msg , alert_type: 'attention' }
                                    AlertaMsg.FormataMsg(notification)
                                    this.timeQr = 0
                                    this.CorCard ='card mb-4 NaoAtt'

                    }
                    if (this.ListaFunc[0].msg == '') {
                            this.CorCard ='card mb-4 Att'
                           
                    }
                    
                    if(notification === '') { 
                        Spinner.EndLoad()
                    }

                    setTimeout(() =>{
                        this.QrCode();
                        this.CorCard ='card mb-4 Inicio'
                    },3500);
                
          
            },
            async DarBaixa(codfunc, codemp, AD_PRODVAPZA, AD_CHAVEPONTO){
                if(this.ATIVABTN == true){
                    Spinner.StarLoad()

                    const usuario = this.$session.get('usuario')
                    let notification = ""

                    let ParametroJson = ''
                    let URLpost = ''


                    URLpost = `/Cesta/InserirCestaRetirada?CODUSU=${usuario.codusu}&_codFunc=${codfunc}&_codEmp=${codemp}&_adProdVapza=${AD_PRODVAPZA}`

                    await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                        if (response.status === 200) {
                            this.ListaFunc = []
                            this.$refs.btnBaixa.style.display = "none";
                            this.timeQr = 0
                        }
                    }).catch(error => {
                        notification = ConexaoAPI.ValidaErro(error)
                        AlertaMsg.FormataMsg(notification)
                    })

                    setTimeout(() =>{
                        this.QrCode();
                        this.CorCard ='card mb-4 Inicio'
                    },1000);

                    if(notification === '') { 
                        Spinner.EndLoad()
                    }
                }
            },
            QrCode() {
                const vm = this; // Preserva o escopo do componente Vue

                let video = document.createElement("video");
                let canvasElement = document.getElementById("canvas");
                let canvas = canvasElement.getContext("2d");
                let loadingMessage = document.getElementById("loadingMessage");
                let outputContainer = document.getElementById("output");
                let outputMessage = document.getElementById("outputMessage");
                let outputData = document.getElementById("outputData");

                function drawLine(begin, end, color) {
                    canvas.beginPath();
                    canvas.moveTo(begin.x, begin.y);
                    canvas.lineTo(end.x, end.y);
                    canvas.lineWidth = 4;
                    canvas.strokeStyle = color;
                    canvas.stroke();
                }

                // Função para trocar entre as câmeras disponíveis
                function switchCamera() {
                    const facingMode = video.getSettings().facingMode;
                    const newFacingMode = facingMode === "user" ? "environment" : "user";

                    const constraints = { video: { facingMode: newFacingMode } };


                    // Parar a câmera atual
                    video.srcObject.getTracks().forEach((track) => {
                        track.stop();
                    });

                    // Iniciar com a nova configuração
                    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                    });
                }

                // Inicialmente tentamos usar a câmera traseira
                const constraints = { video: { facingMode: "environment" } };

                navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                    video.srcObject = stream;
                    video.setAttribute("playsinline", true);
                    video.play();
                    requestAnimationFrame(tick);
                });

                    function tick() {
                        if (vm.timeQr === 0){
                            loadingMessage.innerText = "⌛ carregando video..."
                            if (video.readyState === video.HAVE_ENOUGH_DATA) {
                                loadingMessage.hidden = true;
                                canvasElement.hidden = false;
                                outputContainer.hidden = false;

                                canvasElement.height = video.videoHeight;
                                canvasElement.width = video.videoWidth;
                                canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
                                let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
                                let code = jsQR(imageData.data, imageData.width, imageData.height, {
                                    inversionAttempts: "dontInvert",
                                });
                                if (code) {
                                    drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#15ff00");
                                    drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#15ff00");
                                    drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#15ff00");
                                    drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#15ff00");
                                    outputMessage.hidden = true;
                                    outputData.parentElement.hidden = false;
                                    outputData.innerText = code.data;
                                    if (outputData.textContent.toString() !== '') {
                                        vm.Buscar(outputData.textContent.toString());
                                        vm.timeQr = 1
                                    }
                                } else {
                                    outputMessage.hidden = false;
                                    outputData.parentElement.hidden = true;
                                }
                            }
                            requestAnimationFrame(tick);
                        }
                    }
                
            }
        },
        mounted() {

            if (this.$route.name != null) {
                this.RelRota = this.$route.name
                this.MostraReport()
                this.ListaFunc = []
                this.QrCode()
            }

            const  MeuPlugin  =  {
                install(Vue,options){
                    Vue.prototype.BuscarPorQr = async (qrCode)  =>{
                        Spinner.StarLoad()
                        this.showFiltro = false

                        const usuario = this.$session.get('usuario')
                        let notification = ""

                        let ParametroJson = ''
                        let URLpost = ''

                        this.ListaFunc = []

                        if(this.NRCOLAB != "" || this.QRCODCOLAB != ""){
                            this.ATIVABTN = true
                        }

                        URLpost = `/Cesta/BuscarCesta?CODUSU=${usuario.codusu}&_qrCode=${qrCode}`
                            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                            if (response.status === 200) {
                                if(response.data.length > 0){
                                    this.TOTALCESTAMES = response.data[0].TOTALCESTAMES
                                    if (this.TOTALCESTAMES == null) {
                                        this.TOTALCESTAMES = '0'
                                    }
                                    this.ListaFunc = response.data
                                    this.FiltroTela.QRCODCOLAB = null
                                }
                            }
                        }).catch(error => {
                            this.FiltroTela.QRCODCOLAB = null
                            notification = ConexaoAPI.ValidaErro(error)
                            AlertaMsg.FormataMsg(notification)
                        })
                        if(notification === '') {
                            setTimeout(() =>{
                                Spinner.EndLoad();
                            },1000);
                        }
                        if(this.ListaFunc[0].PRODUTOSVAPZA == "Liberado" || this.ListaFunc[0].PRODUTOSVAPZA == "Liberado (RH)"){
                            this.$refs.LinhaProdVapza.style.color = '#28c76f';
                        }else{
                            this.$refs.LinhaProdVapza.style.color = '#ffcc01';
                            this.$refs.btnBx.style.color = '#ffcc01';
                        }
                        if(this.ListaFunc[0].statusBtn == "igual"){
                            this.$refs.btnBaixa.style.display = "none";
                        }
                    }
                    Vue.use(MeuPlugin)
                }
            }
        },
    }
</script>

<style>
    #infoSpan{
        opacity: 0.5;
        font-size: 0.9rem;
    }
    @media (max-width: 700px){
        #DivBtnBuscar{
            position: relative;
            left: -4px;
        }
        #DivBtnBuscar{
            width: 104%;
        }
        #BtnBuscar{
            width: 100%;
        }
        .containerCard{
            display: block;
        }
        #canvas{
            width: 67%;
            height: 60%;
            border-radius: 4px;
            position: relative;
            margin: 0 auto;
        }
        #outputData{
            background-color: #55DD92;
            padding: 0.25rem;
            font-size: 0.7rem;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            position: relative;
            left: 1.1rem;
        }

        .Inicio{
            background-color: #FFFF
        }

        .Att{
            background-color: #55DD92
        }

        .NaoAtt{
            background-color: #ffcc01;
        }
   }
</style>